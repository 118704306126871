const normalizeParams = <T extends object>(params: T): Required<T> => {
  const newParams: T = { ...params };

  Object.keys(params).map((paramKey) => {
    const paramValue = params[paramKey as keyof T];

    if (typeof paramValue === 'number' && isNaN(paramValue)) {
      newParams[paramKey as keyof T] = 0 as T[keyof T];
    }

    if (typeof paramValue === 'undefined') {
      newParams[paramKey as keyof T] = 0 as T[keyof T];
    }

    return null;
  });

  return newParams as Required<T>;
};

const toNumber = (number: number) => (isNaN(number) ? 0 : number);

/**
 * Get the difference between current and target weights
 *
 * @param weight number
 * @param targetWeight number
 * @returns 0 | number
 */
const getWeightDiff = (weight: number, targetWeight: number) => {
  const normalizedWeight = Math.abs(toNumber(weight));
  const normalizedtargetWeight = Math.abs(toNumber(targetWeight));

  return Math.abs(normalizedWeight - normalizedtargetWeight);
};

const inchesToFeets = (inches: number) => Math.floor(inches / 12);
const feetsToInches = (feets: number) => Math.floor(feets * 12);
const round = (number: number, precision = 0): number => {
  const factor = 10 ** toNumber(precision);

  return Math.floor(toNumber(number) * factor) / factor;
};
const lbToKg = (lb: number) => toNumber(lb) / 2.20462262;
const inchesToCm = (inches: number) => toNumber(inches) * 2.54;

export { normalizeParams, inchesToFeets, feetsToInches, round, lbToKg, inchesToCm, getWeightDiff, toNumber };
