import { extendTheme, defineStyleConfig } from '@chakra-ui/react';
import { Inter } from 'next/font/google';
import localFont from 'next/font/local';

export const InterFont = Inter({
  weight: ['400', '500', '600', '700'],
  subsets: ['latin'],
  display: 'swap',
  variable: '--font-body',
});

export const CabinetGrotskFont = localFont({
  src: [
    {
      path: './assets/fonts/CabinetGrotesk-Regular.woff',
      weight: '400',
      style: 'normal',
    },
    {
      path: './assets/fonts/CabinetGrotesk-Bold.woff',
      weight: '700',
      style: 'bold',
    },
    {
      path: './assets/fonts/CabinetGrotesk-Extrabold.woff',
      weight: '900',
      style: 'extrabold',
    },
  ],
  display: 'swap',
  variable: '--cabinetGroteskFont',
});

const colors = {
  primary: '#5B9255',
  system: {
    blue: '#3633DD',
    pink: '#EB009B',
    yellow: '#FFD442',

    // general
    white: '#FFFFFF',
    black: '#050517',
    error: '#f47777',
    success: '#05952E',
  },
  primaryDark: '#233E20',
};

const Button = defineStyleConfig({
  baseStyle: {
    fontFamily: InterFont.style.fontFamily,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '0.5rem',
    border: 'none',
    textAlign: 'center',
    color: 'primaryDark',
    fontWeight: 500,
    transition: 'all 200ms',

    _hover: {
      cursor: 'pointer',
    },
  },
  sizes: {
    big: {
      padding: '1rem 1.5rem',
      fontSize: '16px',
    },
    small: {
      padding: '0.75rem 1.5rem',
      borderRadius: '0.75rem',
    },
  },
  variants: {
    primary: {
      background: '#3FA134',
      color: '#fff',
      border: 'none',

      _hover: {
        backgroundColor: '#3FA134',

        _disabled: {
          background: '#93cb8c',
        },
      },

      _disabled: {
        background: '#93cb8c',
        opacity: 1,
      },
    },
    secondary: {
      background: '#233E20',
      color: '#FFD442',
      border: 'none',

      _hover: {
        backgroundColor: '#233E20',
      },
    },
    yellow: {
      background: '#FFD442',
      color: '#233E20',
      border: 'none',

      _hover: {
        backgroundColor: '#FFD442',
      },
    },
    blank: {
      background: 'none',
    },
    green: {
      background: '#3FA134',
      color: 'white',
    },
  },
  defaultProps: {
    size: 'big',
    variant: 'primary',
  },
});

const textStyles = {
  h1: {
    fontSize: ['28px', '36px'],
    lineHeight: '110%',
    fontWeight: 700,
    fontFamily: 'var(--cabinetGroteskFont)',
  },
  h2: {
    fontSize: ['32px', '32px'],
    fontWeight: 900,
    lineHeight: '132%',
    letterSpacing: '4%',
  },
  h3: {
    fontSize: ['24px', '24px'],
    fontWeight: 700,
  },
  h4: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '132%',
  },
  subtitle: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '140%',
  },
  body: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%',
  },
  caption: {
    fontSize: '18px',
    fontWeight: 400,
  },
  small: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%',
  },
};

export const theme = extendTheme({
  styles: {
    global: {
      body: {
        color: '#233E20',
      },
      input: {
        fontFamily: InterFont.style.fontFamily,
      },
    },
  },
  fonts: {
    body: InterFont.style.fontFamily,
    heading: CabinetGrotskFont.style.fontFamily,
  },
  colors,
  textStyles,
  components: {
    Button,
    Text: {
      baseStyle: {
        color: 'primaryDark',
      },
    },
    Container: {
      baseStyle: {
        width: 'auto',
        paddingLeft: '6%',
        paddingRight: '6%',
      },
      variants: {
        lg: {
          maxWidth: '71.25rem', // 1140px
        },
        md: {
          maxWidth: '66rem', // TODO: change this value to other
        },
        sm: {
          maxWidth: '27rem', // TODO: change this value to other
        },
      },
      defaultProps: {
        variant: 'lg',
      },
    },
  },
});
