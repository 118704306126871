import { Currency } from '@shared/common';
import { PriceBase } from '@shared/common/src/types/general';

import { Product } from './useAppLead';
import { Coupon, useAppParams } from './useAppParams';
import { useAppRouter } from './useAppRouter';
import { FirebaseFunction, useFirebaseQuery } from './useFirebase';
import { useLocale } from './useLocale';

const DEFAULT_CURRENCY = Currency.USD;

export const useProducts = (product?: Product) => {
  const { currency, isRestWW } = useLocale();
  const { coupon } = useAppParams();
  const { flowName } = useAppRouter();
  const productsQuery = useFirebaseQuery<{ products: Product[] }>(FirebaseFunction.FETCH_PRODUCTS, undefined, {
    staleTime: Infinity,
  });
  const products = productsQuery.data?.data?.products || [];

  const findPriceByCurrency = (prices?: PriceBase[], curr?: Currency) => {
    let priceBase: PriceBase | undefined;

    if (!curr) {
      priceBase = prices?.find((price) => price.currency === currency);
    }

    if (!priceBase) {
      priceBase = prices?.find((price) => price.currency === curr || DEFAULT_CURRENCY);
    }

    return (
      priceBase || {
        price: 0,
        finalPrice: 0,
        currency: Currency.USD,
        discount: 0,
      }
    );
  };

  const findProductPrice = (currentProduct?: Product): PriceBase => findPriceByCurrency(currentProduct?.prices);

  const findTrialPrice = (currentProduct?: Product): PriceBase => findPriceByCurrency(currentProduct?.trial?.prices);

  const productPrice = findProductPrice(product);

  const isWWProduct = (product: Product) => (isRestWW ? isRestWW && product.tags?.includes('ww') : true);

  const findUpsellProduct = (productKey: string, interval = 6) => {
    return products.find((prod) => {
      const { isUpsell, key } = prod;
      const isKeySame = key === productKey;
      const isSubscription = prod.isSubscription;
      const isSameInterval = prod.isSubscription ? prod?.plan?.interval === interval : true;

      if (isUpsell && isKeySame && isSubscription && isSameInterval) {
        return prod;
      }

      return undefined;
    });
  };

  const getInitialPlan = () => {
    if (coupon === Coupon.happy10) {
      return {
        1: '94',
        3: '93',
        6: '92',
      };
    } else if (coupon === Coupon.happy20) {
      return {
        1: '97',
        3: '96',
        6: '95',
      };
    } else if (coupon === Coupon.spooky20) {
      return {
        1: '126',
        3: '125',
        6: '124',
      };
    } else if (coupon === Coupon.spooky) {
      return {
        1: '23',
        3: '22',
        6: '123',
      };
    } else if (coupon === Coupon.singles) {
      return {
        1: '23',
        3: '22',
        6: '128',
      };
    } else if (coupon === Coupon.black) {
      return {
        1: '23',
        3: '22',
        6: '129',
      };
    } else if (flowName === 'trial') {
      return {
        1: '98',
        3: '100',
        6: '99',
      };
    } else if (isRestWW) {
      return {
        1: '66',
        3: '65',
        6: '64',
      };
    }

    return {
      1: '23',
      3: '22',
      6: '21',
    };
  };

  const getProductDetails = (productId: string) => {
    const product = products.find((p) => p.id === productId);
    const price = findProductPrice(product);

    return {
      key: product?.key || '',
      name: product?.name || '',
      finalPrice: findPriceByCurrency(product?.trial?.prices)?.finalPrice || price.finalPrice || 0,
      finalPriceUsd: findPriceByCurrency(product?.prices, Currency.USD).finalPrice || 0,
      currency: price.currency,
      isUpsell: product?.isUpsell || false,
      planInterval: product?.plan?.interval || 0,
    };
  };

  return {
    products,
    price: productPrice.price,
    finalPrice: productPrice.finalPrice,
    discount: productPrice.discount,
    product,
    currency: productPrice.currency,
    planInterval: product?.plan.interval || 6,
    findProductPrice,
    findTrialPrice,
    findProductById: (productId: string) => products?.find((p) => p.id === productId),
    findPriceByCurrency,
    isWWProduct,
    findUpsellProduct,
    initialPlan: getInitialPlan(),
    getProductDetails,
  };
};
