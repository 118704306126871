const calcFirstMonthWeightLoss = (weightLb: number, targetWeightLb: number): number => {
  const diff = weightLb - targetWeightLb;

  if (diff - 10 <= 0) {
    return diff;
  } else if (diff < 11) {
    return 10;
  } else if (diff < 22) {
    return 14;
  } else if (diff < 33) {
    return 18;
  } else if (diff < 44) {
    return 22;
  } else {
    return 26;
  }
};

export const formulas = {
  kgToLbs: (kg: number): number => kg * 2.2046,
  lbsToKg: (lb: number): number => lb / 2.2046,
  feetsToInches: (feets: number): number => feets * 12,
  inchesToFeets: (inches: number): number => inches / 12,
  cmToInches: (cm: number): number => cm * 0.3937,
  inchesToCm: (inches: number): number => inches / 0.3937,
  calcProportion: (x: number, y: number): number => Math.floor((y * 100) / x),
  ozToLiters: (oz: number) => oz * 0.0295735296,
  ozToMilliliters: (oz: number) => oz * 29.57,
  litersToOz: (liters: number) => liters * 33.8140227,
  calcCalories: (weight: number, height: number, age: number) => 10 * weight + 6.25 * height - 5 * age - 161,
  milesToMetres: (miles: number) => miles * 1609.34,
  kilometersToMiles: (kilometers: number) => kilometers * 0.621371192,
  milesToKilometers: (miles: number) => miles / 0.621371192,

  // weight lost
  calcFirstMonthWeightLoss,
  calcWeightLossTime: (weightLb: number, targetWeightLb: number): number => {
    const diff = weightLb - targetWeightLb;
    const afterFirstMonth = calcFirstMonthWeightLoss(weightLb, targetWeightLb);

    return Math.ceil(diff / afterFirstMonth) || 1;
  },

  calcBMI: (
    weightLb: number,
    heightIn: number,
  ): { score: number; category: 'Underweight' | 'Normal' | 'Overweight' | 'Obese'; progressBarPrecents: number } => {
    const score = Number(((weightLb / (heightIn * heightIn)) * 703).toFixed(1));
    const category =
      score <= 18.5
        ? 'Underweight'
        : score >= 18.5 && score <= 24.9
          ? 'Normal'
          : score > 25 && score <= 29.9
            ? 'Overweight'
            : 'Obese';
    const progressBarPrecents = ((score - 18) * 100) / (35 - 18);

    return {
      score,
      category,
      progressBarPrecents: progressBarPrecents < 0 ? 0 : progressBarPrecents > 100 ? 100 : progressBarPrecents,
    };
  },
};
