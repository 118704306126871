import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { AppRoute, QuestionOption } from '../types/general';

type QuestionnaireConfig<QuestionT> = {
  questions: QuestionT[];
  nextPage?: string;
  previousPage?: string;
  quizPath?: string;
  onBack?: () => void;

  /**
   * @deprecated
   */
  onSubmit?: (question: QuestionT, answers: string) => void;
  onQuestionAnwer?: (params: { question: QuestionT; index: number; answer: { label: string; key: string } }) => void;
  onFinish?: () => void;
};

export const useQuestionnaire = <QuestionT extends object>(config: QuestionnaireConfig<QuestionT>) => {
  const { push, events, back } = useRouter();
  const [stepIndex, setStepIndex] = useState(0);
  const [isHydrated, setIsHydrated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const currentQuestion = useMemo(() => config.questions[stepIndex] || ({} as QuestionT), [config, stepIndex]);
  const nextQuestion = useMemo(() => config.questions[stepIndex + 1] || null, [config, stepIndex]);

  const onQuestionSubmit = useCallback(
    (answers: string | QuestionOption, options?: { skipNextQuestion?: boolean }) => {
      const answer = typeof answers === 'string' ? answers : answers.label;
      const answerKey = typeof answers === 'string' ? answers : answers.key;

      if (config.onQuestionAnwer) {
        config.onQuestionAnwer({
          question: currentQuestion,
          index: stepIndex + 2,
          answer: {
            label: answer,
            key: answerKey,
          },
        });
      }

      if (config.onSubmit) {
        config.onSubmit(currentQuestion, answerKey);
      }

      const stepNumber = options?.skipNextQuestion ? 2 : 1;

      if (stepIndex + stepNumber < config.questions.length) {
        setStepIndex(stepIndex + stepNumber);

        const searchParams = new URLSearchParams(window.location.search);
        const queryParams: Record<string, string> = {
          q: String(stepIndex + (stepNumber + 1)),
        };

        if (searchParams.get('flowName')) {
          queryParams.flowName = searchParams.get('flowName') as string;
        }

        push(
          {
            pathname: config?.quizPath || AppRoute.QUIZ,
            query: queryParams,
          },
          undefined,
          { shallow: true },
        );
      } else {
        setIsLoading(true);

        if (config.onFinish) {
          config.onFinish();
        }
      }

      // scroll to top after going to next question
      if (typeof window !== 'undefined' && document) {
        document.getElementById('html')?.scrollTo(0, 0);
      }
    },
    [config, currentQuestion, stepIndex, push],
  );

  const onBack = () => {
    back();
  };

  useEffect(() => {
    const handler = (newUrl: string) => {
      const search = new URLSearchParams(newUrl.split('?')?.[1]);

      setStepIndex(search.get('q') && Number(search.get('q')) ? Number(search.get('q')) - 1 : 0);
    };

    events.on('routeChangeComplete', handler);

    return () => events.off('routeChangeComplete', handler);
  }, [events]);

  useEffect(() => {
    if (!isHydrated) {
      const searchParams = new URLSearchParams(window.location.search);
      setStepIndex(Number(searchParams.get('q')) === 0 ? 0 : Number(searchParams.get('q')) - 1 || 0);

      setTimeout(() => {
        setIsHydrated(true);
      }, 100);
    }
  }, [isHydrated]);

  return {
    currentQuestion,
    nextQuestion,
    isLoading,
    currentStepIndex: stepIndex,
    questionCount: config.questions.length,
    onQuestionSubmit,
    onBack,
  };
};
