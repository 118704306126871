import { useAppRouter } from './useAppRouter';

export enum Coupon {
  happy10 = 'happy10',
  happy20 = 'happy20',
  spooky = 'spooky',
  spooky20 = 'spooky20',
  singles = 'singles',
  black = 'black',
  freeplan = 'freeplan',
}

export const SINGLE_PRODUCT_COUPONS = ['spooky', 'singles', 'black'];

export const useAppParams = () => {
  const { query } = useAppRouter();
  const coupon = query.coupon && (query.coupon as string) in Coupon ? (query.coupon as keyof typeof Coupon) : undefined;

  return {
    coupon,
  };
};
