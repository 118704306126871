import { ChakraProvider } from '@chakra-ui/react';
import { Global } from '@emotion/react';
import { UserContextProvider } from '@shared/common';
import { AnimatePresence } from 'framer-motion';
import { FC, memo, ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

import { Lead } from '~/hooks/useAppLead';
import { CabinetGrotskFont, InterFont, theme } from '~/theme';

import { config } from './config';
import { useLocale } from './hooks/useLocale';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

export const AppProvider: FC<{ children: ReactNode }> = memo(({ children }) => {
  const { isImperial } = useLocale();

  return (
    <>
      <ChakraProvider theme={theme} resetCSS={false}>
        <Global styles={GLOBAL_STYLES} />
        <UserContextProvider
          storageKey={config.name}
          defaultUserData={{ quizAnswers: { isMetric: !isImperial }, webAppUrl: config.webAppUrl } as Lead}
        >
          <AnimatePresence mode='wait' onExitComplete={() => window.scrollTo(0, 0)}>
            <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
          </AnimatePresence>
        </UserContextProvider>
      </ChakraProvider>
    </>
  );
});

AppProvider.displayName = 'AppProvider';

const GLOBAL_STYLES = `
  :root {
    --cabinetGroteskFont: ${CabinetGrotskFont.style.fontFamily};
    --font-body: ${InterFont.style.fontFamily};
  }

    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      -webkit-tap-highlight-color: transparent;
    }

    a {
      text-decoration: none;
      color: inherit;
    }
    img {
      user-drag: none;
      -webkit-user-drag: none;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
  }
  ol, ul {
    list-style-position: inside;
  }

  input:focus-visible {
    box-shadow: none !important;
  }

  .slick-slider {
    width: 100%;
  }

  .slick-slide {
    opacity: 0.6;
  }

  .slick-slide img {
    transform: scale(0.8);
    transition: all 200ms;
  }

  .slick-slide.slick-center {
    opacity: 1;
  }

  .slick-slide.slick-center img {
    transform: scale(1);
  }

  .slick-next {
    right: 20px;
    bottom: -50px;
    top: auto;
    width: 32px;
    height: 32px;
    z-index: 5;
  }

  .slick-prev {
    left: 20px;
    bottom: -50px;
    top: auto;
    width: 32px;
    height: 32px;
    z-index: 5;
  }

  .slick-dots {
    bottom: -30px;
  }

  .slick-dots li {
    margin: 0;
  }

  .slick-dots li button:before {
    color: white;
    opacity: 1;
  }

  .slick-dots li.slick-active button:before {
    color: #5B9255;
    opacity: 1;
  }

  .slick-next:before,
  .slick-prev:before {
    content: '';
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
`;
