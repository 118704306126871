declare global {
  interface Window {
    fbq: (
      action: 'track' | 'trackCustom',
      event: string,
      payload: Record<string, unknown>,
      config?: Record<string, unknown>,
    ) => void | undefined;
  }
}

type MetaPixelEvent = 'Lead' | 'ViewContent' | 'InitiateCheckout' | 'Purchase';

interface Payload {
  value?: number;
  currency?: string;
  eventId?: string;
}

export const MetaPixel = () => {
  const track = (action: 'track' | 'trackCustom', event: string, payload: Payload = {}) => {
    if (typeof window?.fbq !== 'undefined') {
      const { eventId, ...data } = payload;

      window.fbq(action, event, data, { eventID: eventId });
    }
  };

  const trackEvent = (event: MetaPixelEvent, payload?: Payload) => track('track', event, payload);

  const TrackCustom = (event: string, payload?: Record<string, string | number>) =>
    track('trackCustom', event, payload);

  const Lead = (payload?: Payload) => trackEvent('Lead', payload);

  const ViewContent = (payload?: Payload) => trackEvent('ViewContent', payload);

  const InitiateCheckout = (payload?: Payload) => trackEvent('InitiateCheckout', payload);

  const Purchase = (payload?: Payload) => trackEvent('Purchase', payload);

  return {
    Lead,
    ViewContent,
    InitiateCheckout,
    Purchase,
    TrackCustom,
  };
};

export default MetaPixel;
