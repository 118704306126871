import { summarize } from '@shared/summarize';
import { MetaPixel } from '@shared/trackit';

import { useAnalytics } from './useAnalytics';
import { useAppLead } from './useAppLead';
import { useProducts } from './useProducts';

export const useTracking = () => {
  const {
    id,
    lead: { email },
    quizAnswers,
  } = useAppLead();
  const analytics = useAnalytics();
  const { getProductDetails } = useProducts();
  const pixel = MetaPixel();

  const sessionInit = () => {
    analytics.sessionInit();
  };

  const quizQuestionAnswer = (eventParams: { category: string; label: string }) => {
    analytics.log('quiz_question_answer', eventParams);
  };

  const quizStart = () => {
    analytics.log('quiz_start');
  };

  const quizFinish = () => {
    pixel.TrackCustom('QuizFinish', {
      score: summarize({ height: Number(quizAnswers?.height), weight: Number(quizAnswers?.weight) }).bmi.score,
    });

    analytics.log('quiz_finish');
  };

  const emailLeft = (params: { leadId: string; email?: string }) => {
    if (params.email) {
      analytics.setUserData({ email: params.email });
    }

    analytics.setUserProperties({ id: params.leadId });
    analytics.log('email_left');

    analytics.log('conversion', {
      send_to: 'AW-11462412765/hnYOCNr_lZMZEN2T2tkq',
    });

    pixel.Lead({ eventId: ['lead', params.leadId].join('_') });
  };

  const checkoutVisit = () => {
    if (email) {
      analytics.setUserData({ email });
    }

    analytics.log('checkout_visit');
    analytics.log('conversion', {
      send_to: 'AW-11462412765/ieoZCIXXkJMZEN2T2tkq',
    });

    pixel.InitiateCheckout();
  };

  const checkoutSubmit = (params?: Record<string, unknown>) => {
    analytics.log('checkout_submit', params);
  };

  const plansVisit = () => {
    analytics.log('plans_visit');

    pixel.ViewContent();
  };

  const trackPurchase = (productId: string, params?: { method?: string }) => {
    const orderId = [id, productId].join('_');
    const product = getProductDetails(productId);
    const isUpsell = product.isUpsell;

    if (email) {
      analytics.setUserData({ email });
    }

    analytics.log('purchase', {
      transaction_id: orderId,
      value: product.finalPrice,
      currency: product.currency,
      items: [
        {
          item_id: product.key,
          item_name: product.name,
          item_category: product.planInterval,
          price: product.finalPrice,
          quantity: 1,
        },
      ],
      category: isUpsell ? 'upsell' : 'initial',
      label: params?.method,
    });

    if (!isUpsell) {
      pixel.Purchase({ eventId: orderId, currency: product.currency, value: product.finalPrice });

      analytics.log('conversion', {
        send_to: 'AW-11462412765/WCyBCJ3kjIUZEN2T2tkq',
        value: product.finalPrice,
        currency: product.currency,
        transaction_id: orderId,
      });
    }
  };

  return {
    sessionInit,
    quizQuestionAnswer,
    quizStart,
    quizFinish,
    emailLeft,
    trackPurchase,
    plansVisit,
    checkoutVisit,
    checkoutSubmit,
  };
};
