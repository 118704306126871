import { QuizAnswers } from '../../types';
import { useAnalytics } from '../useAnalytics';
import { useAppLead } from '../useAppLead';
import { useAppRouter } from '../useAppRouter';
import { FirebaseFunction, QueryOptions, useFirebaseQuery } from '../useFirebase';

interface LeadReponse {
  id: string;
  quizAnswers?: QuizAnswers;
  email?: string;
}

export const useLeadQuery = (leadId?: string, options?: QueryOptions<LeadReponse>) => {
  const { updateUser } = useAppLead();
  const { query, asPath, replace } = useAppRouter();
  const analytics = useAnalytics();

  const leadQuery = useFirebaseQuery<LeadReponse, { leadId?: string }>(
    FirebaseFunction.FETCH_LEAD,
    { leadId },
    {
      enabled: Boolean(leadId),
      ...options,
      onSuccess: ({ data }) => {
        if (data) {
          updateUser({
            id: data.id,
            quizAnswers: data.quizAnswers,
            email: data.email,
          });

          analytics.setUserProperties({ id: data.id });

          const newQuery = { ...query };

          delete newQuery.leadCode;
          delete newQuery.catchall;

          replace({ pathname: asPath.split('?')[0], query: newQuery }, undefined, { shallow: true });
        }
      },
    },
  );

  return leadQuery;
};
