import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { FUNNELS } from '~/constants';
import { Funnel } from '~/types';

const getFunnelName = (path: string) => {
  const pathsList = path.substring(1).split('/');

  return Object.values(FUNNELS).includes(pathsList[0] as Funnel) ? (pathsList[0] as Funnel) : FUNNELS.main;
};

export const useAppRouter = (nextRoute?: string) => {
  const router = useRouter();
  const asPathname = router.asPath.split('?')[0];
  const funnelName = getFunnelName(asPathname);

  const getDefaultQueryParams = () => {
    const { query } = router;
    const params: Record<string, string> = {};

    if (query.flowName) {
      params.flowName = query.flowName as string;
    }

    if (query.coupon) {
      params.coupon = query.coupon as string;
    }

    return params;
  };

  const getNextFunnelUrl = (overrides?: {
    nextPathname?: string;
    funnelName?: Funnel;
    queryParams?: Record<string, string | null | undefined>;
  }) => {
    const defaultQueryParams: Record<string, string | null | undefined> = {
      ...getDefaultQueryParams(),
      ...overrides?.queryParams,
    };
    const nextFunnelName = overrides?.funnelName || funnelName;
    const nextPathname = overrides?.nextPathname
      ? overrides?.nextPathname
      : nextFunnelName === FUNNELS.main
        ? nextRoute || '/'
        : `/${nextFunnelName}${nextRoute}`;

    return Object.values(defaultQueryParams).filter(Boolean).length > 0
      ? { pathname: nextPathname, query: defaultQueryParams }
      : nextPathname;
  };

  useEffect(() => {
    if (nextRoute && typeof nextRoute === 'string') {
      router.prefetch(nextRoute);
    }
  }, [router, nextRoute]);

  return {
    ...router,
    flowName: router.query.flowName as undefined | string,
    isLocaleDefault: router.locale === 'en-US',
    isCheckoutPage: /\/checkout/.test(router.asPath),
    isPlansOrCommitPage: /\/(commit|plans)/.test(router.asPath),
    funnelName,
    asPathname,
    getNextFunnelUrl,
    pushNextRoute: (overrides?: { pathname?: string; queryParams?: Record<string, string | null | undefined> }) => {
      router.push(
        getNextFunnelUrl({
          nextPathname: overrides?.pathname || nextRoute || '/',
          queryParams: overrides?.queryParams,
        }),
      );
    },
    pushNextFunnelRoute: (overrides?: { funnelName?: Funnel }) => {
      router.push(getNextFunnelUrl(overrides));
    },
  };
};
